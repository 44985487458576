import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store/index.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
Vue.use(VueRouter)
// import layoutBase from './../pages/layout/index.vue'
const layoutBase = () => import('./../pages/layout/index.vue')
const publicLayout = () => import('./../pages/layout/publicLayout.vue')

const router = new VueRouter({
    mode: 'hash',
    routes:[
        {
            path: '/login',
            name: 'login',
            component: () => import('../pages/login/index.vue'),
            meta:{
                noAuth: true,
                noshow: true
            }
        },
        {
            path: '/preview',
            name: 'preview',
            component: () => import('../pages/project/preview.vue'),
            meta:{
                noAuth: true,
                noshow: true
            }
        },
        {
            path: '/registry',
            name: 'registry',
            component: () => import('../pages/registry/index.vue'),
            meta: {
                noAuth: true,
                noshow: true
            }
        },
        {
            path: '/',
            name: 'index',
            redirect: '/indexHome',
            component: publicLayout,
            meta:{
                title: '首页',
                noAuth: true,
                type: 'client'
            },
            children:[
                {
                    path: 'indexHome',
                    name: 'indexHome',
                    meta: {
                        title: '首页',
                        noAuth: true,
                        type: 'client'
                    },
                    component: () => import('../pages/index/index')
                },
                {
                    path: 'personalCenterClient',
                    name: 'personalCenterClient',
                    meta: {
                        title: '个人中心',
                        type: 'client'
                    },
                    component: () => import('../pages/personalCenterClient/index')
                },
                {
                    path: 'clientProjectList',
                    name: 'clientProjectList',
                    meta: {
                        title: '项目列表',
                        type: 'client'
                    },
                    component: () => import('../pages/clientProjectList/index')
                },
                {
                    path: 'clientProjectDetail',
                    name: 'clientProjectDetail',
                    meta: {
                        title: '项目详情',
                        type: 'client'
                    },
                    component: () => import('../pages/clientProjectDetail/index.vue')
                }
            ]
        },
        {
            path: '/home',
            component: layoutBase,
            name: 'home',
            redirect: '/home/index',
            meta:{
                title: '首页',
                icon: 'el-icon-s-home',
                noSubMenu: true
            },
            children:[
                {
                    path: 'index',
                    name: 'home',
                    component: () => import('../pages/home'),
                    meta: {
                        auth: [1,3]
                    }
                }
            ]
        },
        {
            path: '/project',
            component: layoutBase,
            name: 'project',
            redirect: '/project/projectList',
            meta:{
                title: '项目管理',
                icon: 'el-icon-folder-opened'
            },
            children:[
                {
                    path: 'projectList',
                    name: 'projectList',
                    component: () => import('../pages/project/index'),
                    meta:{
                        title: '项目列表',
                        auth: [1,3]
                    }
                },
                {
                    path: 'attachmentList',
                    name: 'attachmentList',
                    component: () => import('../pages/approvalCenter/attachmentList'),
                    meta:{
                        title: '附件列表',
                        auth: [1,3]
                    }
                },
            ]
        },
        {
            path: '/approvalCenter',
            component: layoutBase,
            name: 'approvalCenter',
            meta:{
                title: '审批中心',
                icon: 'el-icon-folder-opened'
            },
            children:[
                {
                    path: 'attachmentApprovalList',
                    name: 'attachmentApprovalList',
                    component: () => import('../pages/approvalCenter/attachmentApprovalList.vue'),
                    meta:{
                        title: '附件审批列表',
                        auth: [1,3]
                    }
                }
            ]
        },
        {
            path: '/setting',
            component: layoutBase,
            name: 'setting',
            meta:{
                title: '系统设置',
                icon: 'el-icon-setting'
            },
            children:[
                {
                    path: 'userList',
                    name: 'userList',
                    component: () => import('../pages/systemSetting/userList.vue'),
                    meta:{
                        title: '用户列表',
                        auth: [1]
                    }
                },
                {
                    path: 'userDetail',
                    name: 'userDetail',
                    component: () => import('../pages/systemSetting/userDetail.vue'),
                    meta:{
                        title: '用户详情',
                        auth: [1,3],
                        noshow: true
                    }
                },
                {
                    path: 'announcementCenterList',
                    name: 'announcementCenterList',
                    component: () => import('../pages/announcementCenter/announcementCenterList.vue'),
                    meta:{
                        title: '公告中心',
                        auth: [1,3]
                    }
                },
            ]
        },
        // {
        //     path: '/personalCenter',
        //     component: layoutBase,
        //     name: 'personalCenter',
        //     meta:{
        //         title: '个人中心',
        //         icon: 'el-icon-folder-opened'
        //     },
        //     children:[
        //         {
        //             path: 'downloadList',
        //             name: 'downloadList',
        //             component: () => import('../pages/downloadCenter/index'),
        //             meta:{
        //                 title: '我的下载'
        //             }
        //         },
        //         // {
        //         //     path: 'myWallet',
        //         //     name: 'myWallet',
        //         //     component: () => import('../pages/personalCenter/myWallet.vue'),
        //         //     meta:{
        //         //         title: '我的钱包'
        //         //     }
        //         // },
        //         {
        //             path: 'favoritesList',
        //             name: 'favoritesList',
        //             component: () => import('../pages/personalCenter/favoritesList.vue'),
        //             meta:{
        //                 title: '我的收藏'
        //             }
        //         },
        //         {
        //             path: 'messageList',
        //             name: 'messageList',
        //             component: () => import('../pages/personalCenter/messageList.vue'),
        //             meta:{
        //                 title: '我的消息'
        //             }
        //         },
        //         // {
        //         //     path: 'projectComplaints',
        //         //     name: 'projectComplaints',
        //         //     component: () => import('../pages/personalCenter/projectComplaints.vue'),
        //         //     meta:{
        //         //         title: '项目投诉'
        //         //     }
        //         // },
        //         {
        //             path: 'contactPlatform',
        //             name: 'contactPlatform',
        //             component: () => import('../pages/personalCenter/contactPlatform.vue'),
        //             meta:{
        //                 title: '联系平台'
        //             }
        //         },
        //         // {
        //         //     path: 'setting',
        //         //     name: 'setting',
        //         //     component: () => import('../pages/personalCenter/index'),
        //         //     meta:{
        //         //         title: '设置'
        //         //     }
        //         // }
        //     ]
        // },
        // {
        //     path: '/404',
        //     name: 'NotFound',
        //     meta: {
        //         noshow: true
        //     },
        //     component: () => import('../pages/result/404.vue')
        // },
        // {
        //     path: '*',
        //     redirect: '/404'
        // }
        {
            path: '*',
            component: layoutBase,
            redirect: '/404',
            meta:{
                noshow: true
            },
            children:[
                {
                    path: '404',
                    component: () => import('../pages/result/404.vue')
                }
            ]
        }
    ]
})
router.beforeEach((to,from,next) => {
    NProgress.start() // 显示进度条
    // next();
    if(from.path == '/') store.commit('rootProcess',true)
    store.commit('pagesProcess',true)
    const token = store.state.token || '';
    // next()
    if (to.meta.noAuth) return next()

    if (to.meta.type !== 'client') {
        if (token) {
            next()
        } else {
            next('/login')
        }
    } else{
        next()
        // if (token) {
        //     next()
        // } else {
        //     next('/')
        // }
    }
    NProgress.done() // 完成进度条

    
    // console.log(to,from, !user.phone, to.path !='/login', to.meta.type, to.meta.type !== 'client')
    // if(!token && to.path !='/login' && to.meta.type !== 'client'){
    //     NProgress.done() // 完成进度条
    //     if(to.path == '/registry'){
    //         next();
    //     }else {
    //         next('/login')
    //     }
        
    // }else {
    //     if(to.meta.level == user.level || !to.meta.level || !to.meta.noAuth){
    //         if (to.meta?.title) document.title = to.meta.title
    //         next()
    //     }else{
    //         next('/');
    //     }
    // }
})

router.afterEach(() => {
    NProgress.done() // 完成进度条
    store.commit('rootProcess',false)
    store.commit('pagesProcess',false)
    // NProgress.done();
    // dealHeadTouter(route)
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router;