/**
 * @description axios 网络请求封装
 * @author xudanfeng
 */
 
 import axios from 'axios'
//  let qs = require('querystring')
 import { Message } from 'element-ui'
 import router from './../router/index.js'
  
//  axios.defaults.baseURL = 'http://47.116.203.37:8080'
 axios.defaults.baseURL = 'https://www.fundrelation.com'
// axios.defaults.baseURL = 'http://ujjdie.natappfree.cc'
 axios.defaults.timeout = 0 // 超时时长
//  axios.defaults.withCredentials = true // 携带cookie
 // 这样设置无效，需要在拦截器中设置
 // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
  
 axios.interceptors.request.use(config => {
    const token = window.localStorage.getItem('token')
   config.headers = {
     'Content-Type': 'application/json',
    //  'token':token,
    //  'traceId':'123456789'
   }
   if (token) config.headers.Token = token
   return config
 }, err => {
   return Promise.reject(err)
 })
  
 axios.interceptors.response.use(response => {
    console.log('response========>', response)
    if(response.status == 401 || response.data.code == 401){
        Message({
            type: 'error',
            message: response.msg
        })  
        router.replace('/login')
     }
     if((response.status != 200 || response.data.code != 200) && response.headers['content-type'] != 'application/octet-stream'){
        Message({
            type: 'error',
            message: response.data.msg
        })  
     }
     
     if (response.headers['content-type'] == 'application/octet-stream') {
        // 获取response Headers
        const headers = response.headers;
        // 获取Content-Disposition字段的值
        const contentDisposition = headers['content-disposition'];
        const filename = contentDisposition && contentDisposition.match(/filename="?([^";]*)"?/)[1];
        return {
            ...response,
            data: {
                data: response.data,
                filename
            }
        }
     }

     
   return response.data
 }, err => {
    console.log('err========>', err)
   if (err.message.includes(401)) {
    Message({
        type: 'error',
        message: '请重新登录'
    }) 
        router.replace('/login')
   }else{
       Message({
            type: 'error',
            message: '操作失败'
        }) 
   }
   return Promise.reject(err)
 })

export default {
    /*axios封装*/
    // GET
    Get(resUrl,params, otherParams = {}){
        return new Promise(function (resolve, reject) {
            axios.get(resUrl,{
                    params: params,
                    ...otherParams
                }).then((res) => {
                    resolve(res)
                }).catch((e)=>{
                    reject(e)
                })

            // }
        })
    },
    // POST
    Post(resUrl,params){
        return new Promise(function (resolve, reject) {
            axios({
                    url: resUrl,
                    method: 'post',
                    data : params
                }).then(function(res){
                    resolve(res)
                }).catch((e)=>{
                    reject(e)
                })
        })
    },
    // POST---FormData
    FormRequest(resUrl,params){
        return new Promise(function (resolve, reject) {
            axios({
                    url: resUrl,
                    method: 'post',
                    data : params
                }).then(function(res){
                    resolve(res)
                }).catch((e)=>{
                    reject(e)
                })
        })
    },
    // Delete
    Delete(resUrl,params){
        return new Promise(function (resolve, reject) {
            axios.delete(resUrl,{
                    params: params
                }).then((res) => {
                    resolve(res)
                }).catch((e)=>{
                    reject(e)
                })

            // }
        })
    },
    // Delete
    DeleteByData(resUrl,data){
        return new Promise(function (resolve, reject) {
            axios.delete(resUrl,{
                    data: data
                }).then((res) => {
                    resolve(res)
                }).catch((e)=>{
                    reject(e)
                })

            // }
        })
    },
    // Delete
    DeleteByUrl(resUrl,params){
        return new Promise(function (resolve, reject) {
            axios.delete(`${resUrl}/${params}`).then((res) => {
                    resolve(res)
                }).catch((e)=>{
                    reject(e)
                })

            // }
        })
    },
    // POST
    DownLoadFile(resUrl,params){
        return new Promise(function (resolve, reject) {

            axios({
                    url: resUrl,
                    method: 'get',
                    params : params,
                    responseType:'blob'
                }).then(function(res){
                    resolve(res)
                }).catch((e)=>{
                    reject(e)
                })
        })
    },
}
  
//  /**
//   * get请求
//   * @param {string} url 请求url
//   * @param {Object} data 请求参数
//   */
//  export function get (url, data = {}) {
//    return axios.get(url, {params: data})
//  }
  
//  /**
//   * post请求
//   * @param {string} url 请求url
//   * @param {Object} data 请求参数
//   */
//  export function post (url, data = {}) {
//    return axios.post(url, querystring.stringify(data))
//  }
  
//  /**
//   * post请求
//   * @param {string} url 请求url
//   * @param {Object} data 请求参数
//   */
//  export function postWithJson (url, data = {}) {
//    return axios.post(url, data)
//  }
  
//  /**
//   * post form参数请求
//   *
//   * @param {string} url 请求 url
//   * @param {Object} data 发送的数据
//   * @return {Promise}
//   */
//  export function form (url, data = {}) {
//    return axios.post(url,
//      querystring.stringify(data),
//      {
//        headers: {
//          'Content-Type': 'application/x-www-form-urlencoded'
//        }
//      }
//    )
//  }